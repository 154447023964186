.r1f7xftf{display:-ms-grid;display:grid;max-width:800px;margin:auto;gap:0.75rem;grid-template-areas:'content' 'video' 'download';text-align:center;}@media screen and (width > 640px){.r1f7xftf{text-align:left;padding-left:2.5rem;-ms-grid-columns:1fr 0.8fr;grid-template-columns:1fr 0.8fr;-ms-grid-rows:auto 1fr auto;grid-template-rows:auto 1fr auto;grid-template-areas:'content video' '.       video' 'download video';}}
.c1mggv78{grid-area:content;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:1rem;padding:1rem;}@media screen and (width > 640px){.c1mggv78{padding-top:1.5rem;}}
.v105kg9c{grid-area:video;}
.d8fnryb{grid-area:download;display:-ms-grid;display:grid;-ms-grid-columns:1fr;grid-template-columns:1fr;gap:1rem;padding-bottom:1.5rem;}@media screen and (width > 640px){.d8fnryb{-ms-grid-columns:auto 1fr;grid-template-columns:auto 1fr;}}
.t9ajj2a{font-size:24px;font-weight:700;color:#1C1C1C;}
.s63qcjs{-webkit-align-self:center;-ms-flex-item-align:center;-ms-grid-row-align:center;align-self:center;}@media screen and (width > 640px){.s63qcjs{-webkit-align-self:self-start;-ms-flex-item-align:self-start;-ms-grid-row-align:self-start;align-self:self-start;}}
.qxk6o8h{display:none;}.qxk6o8h img{max-height:90px;}@media screen and (width > 640px){.qxk6o8h{display:initial;}}
.d2n2qyp{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;gap:0.5rem;-webkit-align-items:center;-webkit-box-align:center;-ms-flex-align:center;align-items:center;}@media screen and (width > 640px){.d2n2qyp{-webkit-align-items:flex-start;-webkit-box-align:flex-start;-ms-flex-align:flex-start;align-items:flex-start;}}
.d1do53pz{grid-area:title;font-weight:600;color:#43CCF8;}
.d5qxcye{grid-area:stores;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;gap:1rem;-webkit-box-pack:start;-ms-flex-pack:start;-webkit-justify-content:flex-start;justify-content:flex-start;}.d5qxcye img{max-width:120px;}
